<template>
  <div>
    <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="40px" v-bind="attrs" v-on="on" class="ms-4">
          <v-img :src="require('@/assets/images/avatars/avatar.svg')"></v-img>
        </v-avatar>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
            <v-avatar size="40px">
              <v-img :src="require('@/assets/images/avatars/avatar.svg')"></v-img>
            </v-avatar>
          </v-badge>
          <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
            <span class="text--primary font-weight-semibold mb-n1">{{ user.name }}</span>
            <small class="text--disabled text-capitalize">{{ user.role }}</small>
          </div>
        </div>

        <!-- <v-divider class="my-2"></v-divider>
        <v-list-item :to="{ name: 'user-profile' }">
          <v-list-item-icon class="me-2">
            <v-icon size="22">mdi-account-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Meus Dados</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- <v-list-item link @click="$root.$emit('UserProfile')">
          <v-list-item-icon class="me-2">
            <v-icon size="22">mdi-account-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Meus Dados</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-divider class="my-2"></v-divider>
        <v-list-item link :to="{ name: 'settings' }">
          <v-list-item-icon class="me-2">
            <v-icon size="22">mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configurações</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>
        <v-list-item link @click="pLogout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <UserProfile />
  </div>
</template>

<script>
import UserProfile from '@/components/modals/UserProfile.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    UserProfile,
  },
  methods: {
    ...mapActions('auth', ['logout']),
    pLogout() {
      this.logout()
      this.$router.push('/login')
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
}
</script>

<style lang="scss" scoped></style>
