import http from '@/http-common'

const create = async (rentalId, data) => {
  return await http
    .post(`/rental/${rentalId}/inspection`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const getAll = async () => {
  return await http
    .get(`/inspection`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const upload = async (id, file) => {
  var formData = new FormData()
  formData.append('file', file)
  return await http
    .put(`/inspection/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  create,
  getAll,
  upload,
}