import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import rental from "./modules/rental";
import account from "./modules/account";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    rental,
    account
  },
  plugins: [createPersistedState({ storage: window.localStorage })]
});
