import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/services/auth'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    permissions: null
  },
  getters: {
    isAuthenticated: state => state.token,
    user: state => state.user,
    hasPermission: (state) => (permission) => {
      if (Array.isArray(permission))

        return permission.some(p => hasPermission(state.user.permission, p))

      return hasPermission(state.user.permission, permission)
    },
    permissions: state => state.permissions,
  },
  mutations: {
    setLogin(state, { token, user }) {
      state.token = token
      state.user = user
    },
    setPermissions(state, p) {
      state.permissions = p
    },
  },
  actions: {
    async getPermissions({ commit }) {
      const permissions = await auth.permission()
      commit('setPermissions', permissions)
    },
    async login({ commit }, data) {
      let response = await auth.login(data)
      commit('setLogin', response)
      return response
    },
    async renewToken({ commit }) {
      try {
        let response = await auth.renewToken()
        commit('setLogin', response)
        return response
      } catch (e) {
        commit('setLogin', { token: null, user: null })
        return false
      }
    },

    logout({ commit }) {
      commit('setLogin', { token: null, user: null })
    },
  },
}

const hasPermission = (userPermission, permission) => {
  permission = BigInt(permission)
  return (BigInt(userPermission) & permission) === permission;
}