<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu no-print"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/dashboard" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require(`@/assets/images/logos/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
          max-height="auto"
          max-width="210px"
          alt="logo"
          contain
          eager
          class="app-logo"
        ></v-img>
      </router-link>
    </div>

    <v-list expand link class="vertical-nav-menu-items pr-5">
      <v-list-item
        :to="{ name: 'dashboard' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Painel Inicial</v-list-item-title>
      </v-list-item>

      <nav-menu-section-title title="CADASTROS" class="mt-1"></nav-menu-section-title>
      <v-list-item
        v-if="hasPermission(32)"
        :to="{ name: 'immobile' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-home-city-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Imóveis</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="hasPermission(256)"
        :to="{ name: 'clients' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-account-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Clientes</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="hasPermission(2048)"
        :to="{ name: 'rentals' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-text-box-check-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Locações</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="hasPermission(32768)"
        :to="{ name: 'contracts' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-file-document-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Contratos</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="hasPermission(32768)"
        :to="{ name: 'inspections' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-home-search</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Vistorias</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="hasPermission(32768)"
        :to="{ name: 'quotations' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-cash-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Orçamentos</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="hasPermission(1073741824, 2147483648, 4294967296)"
        :to="{ name: 'services' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-account-wrench</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Serviços</v-list-item-title>
      </v-list-item>

      <nav-menu-section-title title="RELATÓRIOS" class="mt-1"></nav-menu-section-title>
      <v-list-item
        :to="{ name: 'reports' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-chart-line</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Indicadores</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}
.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
