import Vue from "vue";
import Vuex from "vuex";
import rentalService from "@/services/rental";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    rentals: []
  },
  getters: {
    rentals: state => state.rentals
  },
  mutations: {
    setRentals(state, rentals) {
      state.rentals = rentals;
    }
  },
  actions: {
    async getAll({ commit }) {
      let response = await rentalService.getAll();
      commit("setRentals", response.rentals);
      return response.rentals;
    }
  }
};
