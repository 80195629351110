import http from '@/http-common'

const create = async data => {
  return await http
    .post('/rental', data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const getAll = async () => {
  return await http
    .get('/rental')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const getOneById = async id => {
  return await http
    .get('/rental/' + id)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const getBoleto = async (rentalId, paymentId) => {
  return await http
    .get(`/rental/${rentalId}/payment/${paymentId}/boleto`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const contractCreate = async (rentalId, data) => {
  return await http
    .post(`/rental/${rentalId}/contract`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const contractTemplate = async (rentalId, contractId, documentModel = null) => {
  return await http
    .get(`/rental/${rentalId}/contract/${contractId}/template`, {
      params: { model: documentModel },
      responseType: 'blob',
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const contractUpload = async (rentalId, contractId, data) => {
  var formData = new FormData()
  formData.append('file', data.file)
  return http
    .post(`/rental/${rentalId}/contract/${contractId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const contractCancel = async (rentalId, contractId) => {
  return await http
    .delete(`/rental/${rentalId}/contract/${contractId}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const paymentCreate = async (rentalId, data) => {
  return await http

    .post(`/rental/${rentalId}/payment`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const paymentUpdate = async (rentalId, data) => {
  var id = data.id
  delete data.id

  return await http
    .put(`/rental/${rentalId}/payment/${id}`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const paymentCancel = async (rentalId, paymentId) => {
  return await http
    .delete(`/rental/${rentalId}/payment/${paymentId}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

//  attachment
const attachmentUpload = async (rentalId, data) => {
  var formData = new FormData()
  formData.append('file', data.file)
  formData.append('name', data.name)
  return http
    .post(`/rental/${rentalId}/attachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const attachmentDestroy = async (rentalId, attachmentId) => {
  return await http
    .delete(`/rental/${rentalId}/attachment/${attachmentId}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

import inspection from './inspection.js'

export default {
  create,
  getAll,
  getOneById,
  getBoleto,
  inspection,
  contract: {
    create: contractCreate,
    upload: contractUpload,
    template: contractTemplate,
    cancel: contractCancel,
  },
  payment: {
    create: paymentCreate,
    update: paymentUpdate,
    cancel: paymentCancel,
  },
  attachment: {
    upload: attachmentUpload,
    destroy: attachmentDestroy,
  },
}
