<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent" class="no-print">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>

          <v-spacer></v-spacer>

          <!-- <app-bar-notification></app-bar-notification> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pt-1 pb-4 px-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute  class="px-0 pb-6">
      <div class="boxed-container w-full">
        <v-row class="mx-6 d-flex">
          <v-col cols="12" md="8" class="text-left pl-0 d-none d-sm-inline">
            &copy; {{ new Date().getFullYear() }}
            <a href="https://daher.adm.br/" class="text-decoration-none" target="_blank"></a>
              C.Daher Empreendimentos e Participações</a
            >
          </v-col>
          <v-col cols="12" md="4" class="text-right pr-0">
            <a href="mailto:contato@daher.adm.br" class="me-6 text--secondary text-decoration-none">Fale Conosco</a>
            <a href="tel:+554333222119" class="text--secondary text-decoration-none">+55 43 3322-2119</a>
          </v-col>
        </v-row>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'

import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import AppBarNotification from './components/AppBarNotification.vue'
import { mapActions } from 'vuex'

export default {
  data: () => ({
    isDrawerOpen: null,
    interval: null,
    loading: true,
    cacheInfos: {},
  }),
  methods: {
    ...mapActions('auth', ['renewToken']),
    async rToken() {
      let response = await this.renewToken()
      if (response) return (this.loading = false)
      let currentUrl = this.$route.fullPath
      this.$router.push({
        path: '/login',
        query: { redirect: currentUrl },
      })
      this.$toast.fire({
        icon: 'error',
        title: 'Sua sessão expirou, por favor faça login novamente.',
      })
    },
  },
  mounted() {
    this.rToken()
    this.interval = setInterval(() => {
      this.rToken()
    }, 60000)
    window.addEventListener('beforeprint', event => {
      this.cacheInfos = {
        isDrawerOpen: this.isDrawerOpen,
        isDarkMode: this.$vuetify.theme.dark,
      }
      this.isDrawerOpen = false
      this.$vuetify.theme.dark = false
    })
    window.addEventListener('afterprint', event => {
      this.isDrawerOpen = this.cacheInfos.isDrawerOpen
      this.$vuetify.theme.dark = this.cacheInfos.isDarkMode
    })
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  components: {
    VerticalNavMenu,
    AppBarUserMenu,
    AppBarNotification,
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
