<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  computed: {
    resolveLayout() {
      if (!this.$route?.name) return null
      if (this.$route?.meta?.layout === 'blank') return 'layout-blank'
      return 'layout-content'
    },
  },
}
</script>

<style lang="scss">
/* gap-{x} on x is a number in 1 and 5 */
@for $i from 1 through 5 {
  .gap-#{$i} {
    gap: #{$i * 0.25}rem;
  }

  .gap-x-#{$i} {
    column-gap: #{$i * 0.25}rem;
  }

  .gap-y-#{$i} {
    row-gap: #{$i * 0.25}rem;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>