import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // Módulos
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/meus-dados',
    name: 'user-profile',
    component: () => import('@/views/user-profile/UserProfile.vue'),
  },
  {
    path: '/configuracoes',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
  },
  // Cadastros
  {
    path: '/imoveis',
    name: 'immobile',
    component: () => import('@/views/immobile/Immobile.vue'),
  },
  {
    path: '/imovel/:id',
    name: 'immobile.report',
    component: () => import('../views/immobile/ImmobileReport.vue'),
  },
  {
    path: '/clientes',
    name: 'clients',
    component: () => import('@/views/clients/Clients.vue'),
  },
  {
    path: '/contratos',
    name: 'contracts',
    component: () => import('@/views/contracts/Contracts.vue'),
  },
  {
    path: '/vistorias',
    name: 'inspections',
    component: () => import('@/views/Inspections.vue'),
  },
  {
    path: '/orcamentos',
    name: 'quotations',
    component: () => import('@/views/quotations/Quotation.vue'),
  },
  {
    path: '/orcamentos/:id',
    name: 'quotation.details',
    component: () => import('@/views/quotations/QuotationDetails.vue'),
  },
  {
    path: '/servicos',
    name: 'services',
    component: () => import('@/views/Services.vue'),
  },
  {
    path: '/servicos/:id',
    name: 'service.details',
    component: () => import('@/views/ServiceDetails.vue'),
  },
  // Locações
  {
    path: '/locacoes',
    name: 'rentals',
    component: () => import('@/views/rentals/Rentals.vue'),
  },
  {
    path: '/locacoes/:id',
    name: 'rental.edit',
    component: () => import('@/views/locations/Locations.vue'),
  },
  // Relatórios
  {
    path: '/relatorios',
    name: 'reports',
    component: () => import('@/views/reports/Report.vue'),
  },
  // Acessos
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/access/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/recuperar-senha',
    name: 'recover-password',
    component: () => import('@/views/access/RecoverPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/alterar-senha',
    name: 'change-password',
    component: () => import('@/views/access/ChangePassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // Comum
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/erro',
    name: 'error-404',
    component: () => import('@/views/common/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      return next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    }
  }
  next()
})

export default router
