import Vue from 'vue'
import moment from 'moment'
moment.locale('pt-br')

Vue.filter('currency', function (value) {
  value = parseFloat(value || 0)
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  })
})

Vue.filter('firstName', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.split(' ')[0]
})

Vue.filter("dateDiff", function ([a, b], ...args) {
  const date1 = moment(a);
  const date2 = moment(b);

  const duration = moment.duration(date1.diff(date2));
  return duration.humanize(...args);
});


Vue.filter('date', function (value, format = 'DD/MM/YYYY') {
  if (!value) return ''
  return moment(value).format(format)
})

Vue.filter('idFilter', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.slice(0, 8)
})

Vue.filter('formatAddress', function (address, full = false) {
  if (!address) return ''
  if (full) {
    return ` ${address.street}, ${address.number}
    ${address.complement ? ' - ' + address.complement : ''}
    ${address.district} - ${address.city}/${address.state} - CEP:
    ${address.zipCode}`
  }
  return `${address.street}, ${address.number} - ${address.district} - ${address.city} - ${address.state}`
})

Vue.filter('fileIcon', function (name) {
  const filesTypes = [
    [['png', 'jpg', 'webp', 'jpeg'], 'mdi-file-image'],
    [['pdf'], 'mdi-file-pdf-box'],
    [['docx', 'doc'], 'mdi-file-word-box'],
    [['xlsx', 'xls'], 'mdi-file-excel-box'],
  ]
  
  const type = name.split('.').pop()
  for (const [types, icon] of filesTypes) {
    if (types.includes(type)) return icon
  }
  return 'mdi-file'
})