import md5 from 'md5'
import http from '@/http-common'

const login = async data => {
  return await http
    .post('/auth', {
      email: data.email,
      password: md5(data.password),
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const renewToken = async () => {
  return await http
    .get('/auth/renew-token')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const requestCode = async email => {
  return await http
    .get('/auth/reset-password', {
      params: { email: email },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const setPassword = async (token, password, recaptcha) => {
  password = md5(password)
  return await http
    .post('/auth/reset-password', {
      token,
      password,
      recaptcha,
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const verifyCode = async (token, recaptcha) => {
  return await http
    .post('/auth/reset-password', {
      token,
      recaptcha,
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const permission = async () => {
  return await http
    .get('/auth/permission')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  login,
  renewToken,
  resetPassword: {
    requestCode,
    setPassword,
    verifyCode,
  },
  permission
}
