import Vue from "vue";
import Vuex from "vuex";
import accountService from "@/services/account";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    user: null
  },
  getters: {
    user: state => state.user
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    async getMe({ commit }) {
      let response = await accountService.me();
      commit("setUser", response);
      return response;
    }
  }
};
