import md5 from "md5";
import http from "@/http-common";

const me = async () => {
  return await http
    .get("/account")
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error;
    });
};

const update = async data => {
  return await http
    .put("/account", data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  me,
  update
};
